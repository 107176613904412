














































import { Component, Vue } from "vue-property-decorator";
import { readAdminCities } from "@/store/admin/getters";
import { dispatchDeleteCity, dispatchGetCities } from "@/store/admin/actions";
import { dispatchGetCityAuth } from "@/store/main/actions";
import { readHasAdminAccess } from "@/store/main/getters";

@Component
export default class AdminCities extends Vue {
  private dialog: boolean = false;
  private cityIdToDelete: number = -1;

  get cityToDelete() {
    if (this.cityIdToDelete == -1) {
      return null;
    }

    return this.cities.filter((user) => user.id == this.cityIdToDelete)[0];
  }
  public headers = [
    {
      text: "Name",
      sortable: true,
      value: "name",
      align: "left",
    },
    {
      text: "Aktiv",
      sortable: true,
      value: "is_active",
      align: "left",
    },
    {
      text: "Aktionen",
      value: "id",
      sortable: false,
    },
  ];

  public editCity(cityId: number) {
    this.$router.push({
      name: "main-admin-cities-edit",
      params: { id: cityId.toString() },
    });
  }

  public async deleteCity(cityId: number) {
    this.dialog = true;
    this.cityIdToDelete = cityId;
  }

  public async commitDeleteCity() {
    await dispatchDeleteCity(this.$store, this.cityIdToDelete);
    await dispatchGetCities(
      this.$store,
      readHasAdminAccess(this.$store) ?? false
    );
    this.dialog = false;
    this.cityIdToDelete = -1;
  }

  public abortDeleteCity() {
    this.dialog = false;
    this.cityIdToDelete = -1;
  }

  public async loginAs(cityId: number) {
    await dispatchGetCityAuth(this.$store, cityId);
    this.$router.replace("/");
  }

  get cities() {
    return readAdminCities(this.$store);
  }

  public async mounted() {
    await dispatchGetCities(
      this.$store,
      readHasAdminAccess(this.$store) ?? false
    );
  }
}
